<template>
    <ui-create
        name="fiscalRules"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwfiscalRulesCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                ativo: null,
                csosn: '',
                icsm_entrada: '',
                icsm_saida: '',
                icms_saida_uf: '',
                icms_sub: '',
                ipi: '',
                confins: '',
                reducao: '',
                reducao_fora: '',
                cfop: '',
                cfop_entrada: '',
                cfop_fora: '',
                cfop_entrada_fora: '',
                cst_ipi_saida: '',
                cst_ipi_entrada: '',
                ipi_entrada: '',
                cst_pis_saida: '',
                cst_pis_entrada: '',
                cst_pis_confins_entrada: '',
                cst_pis_confins_saida: '',
                pis_entrada: '',
                confins_entrada: '',
                sped_contribuicao_previ: '',
                sped_tipo_credito: '',
                enquad_ipi_saida: '',
                enquad_ipi_entrada: '',
                cst_confins_entrada: '',
                cst_confins_saida: ''
            },

            rows: [
                [
                    {
                        name: 'ncm',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'N.C.M',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },  
                    {
                        name: 'cest',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CEST',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },   
                ], [
                    {
                        name: 'cfop',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CFOP Interno/ Saída',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },  
                    {
                        name: 'cfop_entrada',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CFOP Interno/ Entrada',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },
                    {
                        name: 'cfop_fora',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CFOP Externo/ Saída',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },  
                    {
                        name: 'cfop_entrada_fora',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CFOP Externo/ Entrada',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },
                ], [
                    {
                        name: 'icms_origem',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'ICMS Origem',
                        handle: 'default',
                        optionText: 'label',
                        options: [
                            {
                                id: 0,
                                label: 'Nacional, exceto as indicadas nos códigos 3, 4, 5, e 8', 
                            },
                            {
                                id: 1,
                                label: 'Estrangeira - Importação direta, exceto a indicada no código 6', 
                            },
                            {
                                id: 2,
                                label: 'Estrangeira - Adquirida no mercado interno, exceto a indicada no código ...', 
                            },
                            {
                                id: 3,
                                label: 'Nacional - Mercadoria ou bem com conteúdo de importação superior a ...', 
                            },
                            {
                                id: 4,
                                label: 'Nacional - Cuja produção tenha sido feita em conformidade ...', 
                            },
                            {
                                id: 5,
                                label: 'Nacional - Mercadoria ou bem com conteúdo de importação inferior ...', 
                            },
                            {
                                id: 6,
                                label: 'Estrangeira - Importação direta, sem similar nacional...', 
                            },
                            {
                                id: 7,
                                label: 'Nacional - Mercadoria ou bem com conteúdo de importação superior ...', 
                            },
                        ],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 6,
                            xl: 6
                        },
                    },
                    {
                        name: 'csosn',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CSOSN',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'cst',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST (Tributação)/ Saída',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'cst_entrada',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST (Tributação)/ Entrada',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 2,
                            xl: 2
                        }
                    },
                ], [
                    {
                        name: 'icms_st',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'ICMS/ Modalidade BC do ICMS ST',
                        handle: 'default',
                        optionText: 'label',
                        options: [
                            {
                                id: 0,
                                label: 'Preço tabelado ou máximo sugerido', 
                            },
                            {
                                id: 1,
                                label: 'Lista Negativa (valor)', 
                            },
                            {
                                id: 2,
                                label: 'Lista Positiva (valor)', 
                            },
                            {
                                id: 3,
                                label: 'Lista Neutra (valor)', 
                            },
                            {
                                id: 4,
                                label: 'Margem Valor Agregado (%)', 
                            },
                            {
                                id: 5,
                                label: 'Pauta (valor)', 
                            },
                            {
                                id: 6,
                                label: 'Valor da Operação', 
                            },
                        ],
                        col: {
                            default: 12,
                            xs: 8,
                            sm: 8,
                            md: 8,
                            lg: 6,
                            xl: 6
                        },
                    },
                    {
                        name: 'icms_st_margem',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Porcentagem',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 4,
                            sm: 4,
                            md: 4,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'icms_st_tabela',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'ICMS/ Tabela MVA/Pauta',
                        handle: 'default',
                        optionText: 'label',
                        options: [
                        ],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 4,
                            xl: 4
                        },
                    },
                ], [
                    {
                        name: 'cst_pis',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST PIS Interno/ Saída',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },  
                    {
                        name: 'cst_pis_entrada',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST PIS Interno/ Entrada',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },
                    {
                        name: 'cst_pis_fora',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST PIS Externo/ Saída',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },  
                    {
                        name: 'cst_pis_entrada_fora',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CST PIS Externo/ Entrada',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        }
                    },
                ],
            ],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 128 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_csosn_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CSOSN é obrigatório.'
            },

            options_form_icms_entrada: {
                length: 4,
                precision: 2
            },
            form_icms_entrada_rules: {
                required: value => !!value || 'ICMS de Entrada é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_icms_saida: {
                length: 4,
                precision: 2
            },
            form_icms_saida_rules: {
                required: value => !!value || 'ICMS de Saida é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_icms_saida_uf: {
                length: 4,
                precision: 2
            },
            form_icms_saida_uf_rules: {
                required: value => !!value || 'ICMS de Saida UF é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_icms_sub: {
                length: 4,
                precision: 2
            },
            form_icms_sub_rules: {
                required: value => !!value || 'ICMS_SUB é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_ipi: {
                length: 4,
                precision: 2
            },
            form_ipi_rules: {
                required: value => !!value || 'IPI é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_confins: {
                length: 4,
                precision: 2
            },
            form_confins_rules: {
                required: value => !!value || 'Confins é obrigatório.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_reducao: {
                length: 4,
                precision: 2
            },
            form_reducao_rules: {
                required: value => !!value || 'Reducao é obrigatória.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            options_form_reducao_fora: {
                length: 4,
                precision: 2
            },
            form_reducao_fora_rules: {
                required: value => !!value || 'Reducao fora é obrigatória.',
                counter: value => value.length <= 5 || 'Dados inválido!'
            },

            form_cfop_interno_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CFOP Saida é obrigatório.',
                min: v => v.length >= 4 || 'CFOP Inválido!'
            },

            form_cfop_interno_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CFOP Entrada é obrigatório.',
                min: v => v.length >= 4 || 'CFOP Inválido!'
            },

            form_cfop_externo_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CFOP Saida é obrigatório.',
                min: v => v.length >= 4 || 'CFOP Inválido!'
            },

            form_cfop_externo_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CFOP Entrada é obrigatório.',
                min: v => v.length >= 4 || 'CFOP Inválido!'
            },

            form_cst_ipi_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST IPI de saida é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_ipi_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST IPI de entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_ipi_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'IPI de entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_pis_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST PIS saida é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_pis_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST PIS entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_pis_confins_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST PIS Confins entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_pis_confins_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST PIS Confins saida é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_pis_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST PIS Confins saida é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_confins_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CONFINS entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_sped_contribuicao_previ_rules: {
                required: value => !!value || 'Sped contribuição é obrigatório.',
                counter: value => value.length <= 20 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Dados Inválido!'
            },

            form_sped_tipo_credito_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'Sped tipo de credito é obrigatório.',
                min: v => v.length >= 3 || 'Dados Inválido!'
            },

            form_enquad_ipi_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'Enquadramento ipi saida é obrigatório.',
                min: v => v.length >= 5 || 'Dados Inválido!'
            },

            form_enquad_ipi_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'Sped tipo de credito é obrigatório.',
                min: v => v.length >= 5 || 'Dados Inválido!'
            },

            form_cst_confins_entrada_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST Confins entrada é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            },

            form_cst_confins_saida_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!',
                required: value => !!value || 'CST Confins saida é obrigatório.',
                min: v => v.length >= 1 || 'Dados Inválido!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar Regra Fiscal: ${this.id}` : 'Cadastrar Regra Fiscal'
        }
    },

    mounted(){
        this.$root.$on('load-data-fiscalRules', (data) => {
            this.form = data
        })
    }
}
</script>